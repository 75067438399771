import React, {Component} from 'react'
import IntlMessages from '../util/IntlMessages'
import SettingLeftBar from './SettingLeftBar'
import {educational, getPath} from '../constants/Common'
import _ from 'lodash'
import FourCs from './Diamondeducation/FourCs'
import PolicedDiamond from './Diamondeducation/CutAndPolishedDiamonds'
import WholeSaleDiamond from './Diamondeducation/WholesaleDiamonds'
import FancyDiamonds from './Diamondeducation/FancyDiamonds'
import LooseDiamonds from './Diamondeducation/LooseDiamonds'
import CertifiedDiamonds from './Diamondeducation/CertifiedDiamonds'


class Educational extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnName: [],
            activeKey: getPath(props.location.pathname.split('/')),
        }
    }

    renderRightSide = () => {
        const { activeKey } = this.state;
        const { locale } = this.props.commonData.locale;
        switch (activeKey) {
            case educational.edu4cs.key:
                return <FourCs
                    fields={this.props?.fields?.[`${locale}Cs`]?.frontmatter}
                />
            case educational.educutandpolisheddiamonds.key:
                return <PolicedDiamond
                    fields={this.props?.fields?.[`${locale}CutPolished`]?.frontmatter}
                />
            case educational.eduwholesalediamonds.key:
                return <WholeSaleDiamond
                    fields={this.props?.fields?.[`${locale}Wholesale`]?.frontmatter}
                />
            case educational.educertifieddiamonds.key:
                return <CertifiedDiamonds
                    fields={this.props?.fields?.[`${locale}Certified`]?.frontmatter}
                />
            case educational.edufancydiamonds.key:
                return <FancyDiamonds
                    fields={this.props?.fields?.[`${locale}Fancy`]?.frontmatter}
                />
            case educational.eduloosediamonds.key:
                return <LooseDiamonds
                    fields={this.props?.fields?.[`${locale}Loose`]?.frontmatter}
                />

            default:
                return <FourCs
                    fields={this.props?.fields?.[`${locale}Cs`]?.frontmatter}
                />
        }
    }

    render() {
        const { activeKey } = this.state

        let activeName = _.replace(activeKey, /-/g, ' ')
        let activeKeyName = _.startCase(_.toLower(activeName))
        if (activeKeyName === 'Matchpair Settings') activeKeyName = 'Match Pair Settings'

        return (
            <div className="content-body">
                <div className="page-head d-flex align-items-center brand-top-block">
                    <h3>
                        <IntlMessages id="app.Educational" />
                    </h3>
                    <img src={require('../../static/assets/svg/rightArrow.svg')} alt="" />
                    <div>
                        <IntlMessages id={`app.${activeKey}`} />
                    </div>
                </div>
                <div className="diamond-detail-content d-flex d-xs-block">
                    <div className="left_detail_part">
                        <SettingLeftBar tabs={educational} activeKey={activeKey} />
                    </div>
                    <div className="right_detail_part account-all-page setting-right-section">
                        {this.renderRightSide()}
                    </div>
                </div>
            </div>
        )
    }
}

/*const mapStateToProps = state => ({
    loading: state.commonData.loading
})

export default connect(
    mapStateToProps,
    { setLoader }
)(Educational)*/

export default Educational
