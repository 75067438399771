import React, {Component} from 'react'
import IntlMessages from '../../util/IntlMessages'
import LazyImage from '../common/lazyImage'
import {goToLink} from "../../services/helper";

class FourCs extends Component {
    render() {
        const { fields } = this.props;
        return (
            <div>
                <div className="diamond-education">
                    <h2 className="mb-25">
                        <IntlMessages id="app.4cs" />
                    </h2>
                    {fields?.lists && fields.lists.map((item,i) =>
                    <div className={i !== fields?.lists.length - 1  ? "mb-50" : "mb-25"}>
                        <div className="diamond-education-img mb-25">
                            <LazyImage widthAuto={true} className="width-full" src={item?.img?.publicURL} alt="" />
                        </div>
                        <div className="diamond-education-dec">
                            <h3>{item.title}</h3>
                            <div
                            dangerouslySetInnerHTML={{__html: item.description}}
                            />
                        </div>
                    </div>)}
                </div>
                    <div>
                        <div className="form_blue_btn">
                            <button onClick={()=>goToLink("signup")} className="button primary-fill">
                                <IntlMessages id="app.signin.register" />
                            </button>
                        </div>
                    </div>
            </div>
        )
    }
}

export default FourCs
