import React, { Component } from 'react'
import IntlMessages from '../../util/IntlMessages'
import LazyImage from '../common/lazyImage'
import {goToLink} from "../../services/helper";

class CutAndPolishedDiamonds extends Component {
    render() {
        const { fields } = this.props;
        return (
            <div>
                <div className="diamond-education">
                    <h2 className="mb-25">
                        <IntlMessages id="app.cut-and-polished-diamonds" />
                    </h2>
                    <div className="mb-25">
                        <div className="diamond-education-img mb-25">
                            <LazyImage widthAuto={true} className="width-full" src={fields?.img?.publicURL} alt="" />
                        </div>
                        <div className="diamond-education-dec">
                            <div
                                dangerouslySetInnerHTML={{__html: fields?.description}}
                            />
                        </div>
                    </div>
                </div>

                    <div>
                        <div className="form_blue_btn">
                            <button onClick={()=>goToLink("signup")} className="button primary-fill">
                                <IntlMessages id="app.signin.register" />
                            </button>
                        </div>
                    </div>
            </div>
        )
    }
}

export default CutAndPolishedDiamonds
