import React from "react";
import Diamondeducation from "../components/Diamondeducation"
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import {connect} from "react-redux";
import SEO from "../components/SEO";

const FancyDiamonds = (props) => {
    return (
        <Layout>
            <SEO {...(props?.data?.seo?.frontmatter || {})}/>
            <Diamondeducation {...props} fields={props?.data}/>
        </Layout>
    )
};
const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, null)(FancyDiamonds);

export const query = graphql`
fragment FancyFragment on MarkdownRemark {
       frontmatter {
      title
      description
      img{
            publicURL
      }
    }
  }
  query FancyPageQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "fancy-seo"}}) {
        frontmatter {
            title
            description
        }
  }
  enFancy: markdownRemark(frontmatter: {templateKey: {eq: "en-fancy"}}) {
   ...FancyFragment
  }
    deFancy: markdownRemark(frontmatter: {templateKey: {eq: "de-fancy"}}) {
   ...FancyFragment
  }
    esFancy: markdownRemark(frontmatter: {templateKey: {eq: "es-fancy"}}) {
   ...FancyFragment
  }
    frFancy: markdownRemark(frontmatter: {templateKey: {eq: "fr-fancy"}}) {
   ...FancyFragment
  }  
  heFancy: markdownRemark(frontmatter: {templateKey: {eq: "he-fancy"}}) {
   ...FancyFragment
  }
    itFancy: markdownRemark(frontmatter: {templateKey: {eq: "it-fancy"}}) {
   ...FancyFragment
  }
    jaFancy: markdownRemark(frontmatter: {templateKey: {eq: "ja-fancy"}}) {
   ...FancyFragment
  }
    zhFancy: markdownRemark(frontmatter: {templateKey: {eq: "zh-fancy"}}) {
   ...FancyFragment
  }
  
  
 }
`